import React from 'react'
import FooterText from '../ui/atoms/FooterText'
import { Brand } from './typings'

const nissan: Brand = {
  clientInfo: {
    displayName: 'Nissan',
    accountNumberLengths: [16],
    footerDisplay: 'Nissan Finance Portal',
    footerLink: 'https://www.nissanfinance.com/',
    applicationUrl: '',
    dsecApigeeClientId: {
      development: 'b5mrxn9Yj51NoyfhLcrpoBnnVclD8tGZ',
      production: '9ltSXMTsYZl7PYEMbmPaXSDeSPCWa7ES'
    },
    persistApigeeClientId: { development: '', production: '' },
    setpayApigeeClientId: {
      development: '',
      production: ''
    },
    metaTagCardTitle: {
      en: 'Credit Card',
      es: 'Tarjeta de Crédito'
    },
    metaTagCardDescription: {
      en: `Log into your Nissan credit card account online to pay your bills, check your FICO score, sign up for paperless billing, and manage your account preferences`,
      es: `Ingrese a su cuenta de la Tarjeta de Crédito Nissan en línea para pagar sus facturas, revisar su puntuación FICO, inscribirse en facturas digitales y manejar su cuenta.`
    },
    languages: ['en'],
    vanityUrl: 'Nissan.com',
    hostname: 'nissan.syf.com',
    linkingRedirect: '',
    ivaKey: '',
    hasMss: true,
    hasCpl: false,
    promoUrl: '',
    hasCplOldErrorCode: false,
    isMultiAccount: false,
    footerContent: {
      en: (
        <>
          <FooterText>
            Your Nissan® Visa Signature Credit Card and Nissan® Visa Credit Card
            is issued by Synchrony Bank. The Synchrony Bank Privacy Policy
            governs the use of the Nissan® Visa Signature Credit Card and
            Nissan® Visa Credit Card. The use of this site is governed by the
            use of the Synchrony Bank Internet Privacy Policy, which is
            different from the privacy policy of Nissan.
          </FooterText>
        </>
      ),
      es: ''
    },
    phoneNumbers: {
      loan: '',
      dualcard: '1-866-406-8451',
      fraud: '1-866-406-8451',
      customerService: '1-866-406-8451',
      plcc: ''
    },
    iOSAppId: '',
    androidApp: {
      id: '',
      author: '',
      title: '',
      image: ''
    },
    crossBorder: false,
    cplHeroBannerDisplayName: '',
    termsOfService: {
      en: '/essimages/cs/groups/ess_webasset/dualcard/@nissan/en/html/ess003467.html',
      es: ''
    },
    onlinePrivacy: {
      en: '/essimages/cs/groups/ess_webasset/dualcard/@nissan/en/html/ess003457.html',
      es: ''
    },
    privacyPolicy: {
      en: '/essimages/cs/groups/ess_webasset/dualcard/@nissan/en/html/ess003459.html',
      es: ''
    },
    websiteUsage: {
      en: '/essimages/cs/groups/ess_webasset/dualcard/@nissan/en/html/ess003460.html',
      es: ''
    },
    voice: {
      cardName: '',
      google: {
        termsAndConditionsLink: ''
      },
      alexa: {
        termsAndConditionsLink: ''
      }
    }
  },
  palette: {
    button: {
      primary: {
        text: '#FFFFFF',
        hover: '#a80027'
      },
      secondary: {
        hover: '#f2dadf'
      },
      background: '#c3002f'
    },
    field: {
      icon: '#333333',
      focus: '#34657F',
      label: ''
    },
    progressStepBar: {
      fill: '#333333',
      label: '#333333'
    },
    progressBar: {
      complete: '',
      error: '#c63527',
      inProgress: '#0072ad',
      background: '#e9eaeb'
    },
    checkbox: {
      background: '#c3002f',
      font: '#FFFFFF'
    },
    switch: { background: '' },
    heroBackground: '#F2f2f2',
    bodyBackground: '#f2f2f2',
    iconBackground: '#cacaca',
    headerBackground: '#fff',
    modalBackground: '#fff',
    headerDivider: '#333',
    footerDivider: '#333',
    contrastingText: '#FFF',
    bodyText: '#333',
    languageSelector: '#333',
    focus: '',
    link: '#c3002f',
    footer: {
      background: '#fcfcfc',
      text: '#333333',
      link: '#333333'
    },
    formBackground: ''
  },
  images: {
    logo: {
      en: '/assets/nissan/logo.png',
      es: '/assets/nissan/logo.png'
    },
    hero: '/assets/nissan/background.svg',
    heroBgDesktop: '',
    heroBgMobile: '',
    mainPromo: {
      en: '/assets/nissan/promo.svg',
      es: '/assets/nissan/promo.svg'
    },
    voiceCplLogo: ''
  }
}

export default nissan
